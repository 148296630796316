<template>
  <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;" />
  <div class="card">
    <div class="p-grid" style="margin-top:0">
      <div class="p-col-4">
        <label>项目名称：</label>
        <MultiSelect @keypress="$event.keyCode==13?query():{}" style="width:60%" v-model="dataTable.queryParams.projectIds" :options="projectList" :filter="true" optionLabel="name" placeholder="请选择项目" />
      </div>
      <div class="p-col-4">
        <label>设备类型：</label>
        <Dropdown @keypress="$event.keyCode==13?query():{}" style="width:60%" :filter="true" placeholder="请选择" v-model="dataTable.queryParams.devType" :options="deviceTypeList" optionLabel="name" />
      </div>
      <div class="p-col-4" v-if="hide">
        <label style="width: 80px;display: inline-flex;justify-content: flex-end">时间段：</label>
        <Calendar @keypress="$event.keyCode==13?query():{}" style="width: 240px" placeholder="请选择" :manualInput="false" selectionMode="range" dateFormat="yy/mm/dd" v-model="dataTable.queryParams.timeRange"
          :numberOfMonths="2" :show-icon="true" />
      </div>
      <div class="p-col" style="display: flex;justify-content: flex-end;padding-right: calc(33.33% - 330px)">
        <Button @click="reset()" style="height:33px;border-color: #d9d9d9" label="重置" class="p-button-outlined p-button-secondary p-mr-2" />
        <Button style="background: #12A25D;height:33px;" label="查询" class="p-mr-2" @click="query()" />
        <Button style="height:33px;color: #12A25D;border-color: #FFFFFF" :label="hideTitle" @click="hideSelection()" :icon="'btn pi ' + (hide ? 'pi-chevron-up' : 'pi-chevron-down')" iconPos="right"
          class="p-button-link" />
      </div>
    </div>
    <div>
    </div>
  </div>

  <div class="card">

    <Toolbar class="p-mb-4" style="background-color: #fff;border:none;padding-bottom:0px;">
      <template #left>
        <Button v-if="buttons.ftbBt" @click="compare('split')" :disabled="dataTable.selected.length==0" style="background: #12A25D" :label="'分图表对比（'+dataTable.selected.length+'）'" class="p-mr-2" />
        <Button v-if="buttons.tytbBt" @click="compare('one')" :disabled="dataTable.selected.length==0" style="background:#12A25D" :label="'同一图表对比（'+dataTable.selected.length+'）'" class="p-mr-2" />
      </template>
    </Toolbar>
    <DataTable @row-select-all="selectedChange()" @row-select="selectedChange()" @row-unselect="selectedChange()" :resizableColumns="true" columnResizeMode="expand" style="width:100%;" :rowHover="true"
      class="p-datatable-customers" :value="dataTable.gridData" v-model:selection="dataTable.selected" :lazy="true" :loading="dataTable.loading" @page="onPage($event)" :totalRecords="dataTable.totalRecords"
      responsiveLayout="scroll" :paginator="true" :rows="10" paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport" :rowsPerPageOptions="[10,20,50,100]"
      currentPageReportTemplate="共 {totalRecords} 条">
      <template #empty>
        没有发现数据。
      </template>
      <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
      <Column field="fullid" header="设备编号" style="min-width:8rem"></Column>
      <Column field="dname" header="设备名称" style="min-width:10rem"></Column>
      <Column field="dtype" header="设备类型" style="min-width:10rem"></Column>
      <Column field="fulladdr" header="地点" style="min-width:10rem"></Column>
      <Column field="fullposi" header="坐标" style="min-width:10rem"></Column>
      <Column field="projectName" header="项目" style="min-width:10rem"></Column>
      <template #paginatorLeft></template>
      <template #paginatorRight>
        <div class="p-field">
          <label>跳至&nbsp;&nbsp;</label>
          <InputNumber @keypress="jumpPage()" v-tooltip.focus="'按下回车键后跳转'" inputStyle="width: 40px;" id="integeronly" v-model="dataTable.jumpPageNum" />
          <label>&nbsp;&nbsp;页</label>
        </div>
      </template>
    </DataTable>
  </div>

  <Toast />

</template>

<script>
export default {
  components: {},
  data() {
    return {
      hide: false,
      hideTitle: "展开",
      breadcrumb: {
        home: { label: "种植项目", to: "#" },
        items: [{ label: " 数据分析", to: "#" }],
      },
      projectList: [],
      deviceTypeList: [],
      dataTable: {
        gridData: [],
        selected: [], // 页面默认要使用，不能是null
        queryParams: {
          projectIds: null,
          devType: null,
          timeRange: [], // 不能为null，默认要使用
        },
        lazyParams: {
          page: 0,
        },
        loading: true,
        totalRecords: 0,
        jumpPageNum: 1,
        lastParams: null,
        
      },
      buttons: {
          ftbBt: false,
          tytbBt: false,
        },
    };
  },
  mounted() {
    console.log(JSON.parse(localStorage.getItem("menuList")));
    if (localStorage.getItem("menuList")) {
      JSON.parse(localStorage.getItem("menuList")).forEach((item, index) => {
        if (
          item.name == "种植项目" &&
          JSON.parse(localStorage.getItem("menuList"))[index].children
        ) {
          JSON.parse(localStorage.getItem("menuList"))[index].children.forEach(
            (v, i) => {
              console.log(v.name);
              if (
                v.name == "数据分析" &&
                JSON.parse(localStorage.getItem("menuList"))[index].children[i]
                  .buttons
              ) {
                JSON.parse(localStorage.getItem("menuList"))[index].children[
                  i
                ].buttons.forEach((a) => {
                  switch (a.name) {
                    case "分图表对比":
                      this.buttons.ftbBt = true;
                      break;
                    case "同一图表对比":
                      this.buttons.tytbBt = true;
                      break;
                    default:
                      return;
                  }
                });
              }
            }
          );
        }
      });
    }
    console.log(this.buttons);
    this.loadTable();
    this.initData();
  },
  methods: {
    query() {
      let params = {};
      if (
        this.dataTable.queryParams.timeRange &&
        this.dataTable.queryParams.timeRange.length == 2
      ) {
        params.startDate = new Date(
          this.dataTable.queryParams.timeRange[0]
        ).toLocaleDateString();
        params.endDate = new Date(
          this.dataTable.queryParams.timeRange[1]
        ).toLocaleDateString();
      }
      if (
        this.dataTable.queryParams.projectIds &&
        this.dataTable.queryParams.projectIds.length > 0
      ) {
        params.projectIds = "";
        for (let i in this.dataTable.queryParams.projectIds) {
          let curr = this.dataTable.queryParams.projectIds[i];
          params.projectIds += curr.code + ",";
        }
        params.projectIds = params.projectIds.substring(
          0,
          params.projectIds.length - 1
        );
      }
      if (this.dataTable.queryParams.devType) {
        params.devType = this.dataTable.queryParams.devType.code;
      }
      this.loadTable(params);
    },
    reset() {
      for (let i in this.dataTable.queryParams) {
        this.dataTable.queryParams[i] = null;
      }
      this.loadTable();
    },
    compare(type) {
      // if(this.dataTable.selected.length < 2){
      //   this.$toast.add({severity: 'warn', summary: '选择错误！', detail: '请至少选择两个设备进行对比！', life: 3000});
      //   return;
      // }
      let ids = [];
      for (let i in this.dataTable.selected) {
        let sel = this.dataTable.selected[i];
        ids.push(sel.pid + "-" + sel.did);
      }

      // 拆分时间
      if (type == "split") {
        this.$router.push({
          path: "/data/compare/split",
          query: {
            series: this.dataTable.selected[0].series,
            type: this.dataTable.selected[0].dtype,
            devs: JSON.stringify(ids),
            startDate: this.dataTable.queryParams.timeRange[0]
              ? new Date(
                  this.dataTable.queryParams.timeRange[0]
                ).toLocaleDateString()
              : null,
            endDate: this.dataTable.queryParams.timeRange[1]
              ? new Date(
                  this.dataTable.queryParams.timeRange[1]
                ).toLocaleDateString()
              : null,
          },
        });
      } else if (type == "one") {
        this.$router.push({
          path: "/data/compare/one",
          query: {
            series: this.dataTable.selected[0].series,
            type: this.dataTable.selected[0].dtype,
            devs: JSON.stringify(ids),
            startDate: this.dataTable.queryParams.timeRange[0]
              ? new Date(
                  this.dataTable.queryParams.timeRange[0]
                ).toLocaleDateString()
              : null,
            endDate: this.dataTable.queryParams.timeRange[1]
              ? new Date(
                  this.dataTable.queryParams.timeRange[1]
                ).toLocaleDateString()
              : null,
          },
        });
      }
    },
    selectedChange() {
      let pre = null;
      for (let i in this.dataTable.selected) {
        let row = this.dataTable.selected[i];
        if (pre == null) {
          pre = row.dtype;
        } else if (pre != row.dtype) {
          this.$toast.add({
            severity: "warn",
            summary: "选择错误！",
            detail: "不同类型的设备不具备可比性，请重新选择！",
            life: 3000,
          });
          this.dataTable.selected = [];
        }
      }
    },
    hideSelection() {
      if (!this.hide) {
        this.hideTitle = "收起";
        this.hide = true;
      } else {
        this.hideTitle = "展开";
        this.hide = false;
      }
    },

    initData() {
      this.$http("/project/ownProject", "get", null, (resp) => {
        for (let i in resp.data) {
          let proj = resp.data[i];
          this.projectList.push({
            name: proj.name,
            code: proj.id,
          });
        }
      });

      this.$http("/device/getTypeList", "get", null, (resp) => {
        for (let i in resp.data) {
          let type = resp.data[i];
          this.deviceTypeList.push({
            name: type,
            code: type,
          });
        }
      });
    },

    jumpPage() {
      if (this.dataTable.jumpPageNum > 0 && event.keyCode == 13) {
        this.dataTable.lazyParams.page = this.dataTable.jumpPageNum - 1;
        this.loadTable(this.dataTable.lastParams);
      }
    },

    /**
     * 加载表格数据
     */
    loadTable(params) {
      let me = this;
      me.loading = true;
      this.dataTable.lastParams = params;
      let targetParmas = {
        current: this.dataTable.lazyParams.page + 1,
        size: this.dataTable.lazyParams.rows,
      };
      if (params) {
        Object.assign(targetParmas, params);
      }
      this.$http(
        "/device/listCompareDevice",
        "get",
        targetParmas,
        function (response) {
          me.dataTable.gridData = response.data.records;
          me.dataTable.totalRecords = response.data.total;
          me.dataTable.loading = false;
        }
      );
    },

    onPage(event) {
      this.dataTable.lazyParams = event;
      this.loadTable(this.dataTable.lastParams);
    },
  },
};
</script>

<style lang="scss" scoped>
.a-button {
  color: #12a25d;
  font-size: 14px;
  margin-left: 8px;
  margin-right: 8px;
}

.admin-status {
  width: 56px;
  height: 22px;
  border: 1px double #91d5ff;
  color: #1890ff;
  font-size: 12px;
  padding: 4px 8px 4px 8px;
  background-color: #e6f7ff;
}

.user-status {
  width: 56px;
  height: 22px;
  border: 1px double #b7eb8f;
  color: #52c41a;
  font-size: 12px;
  padding: 4px 8px 4px 8px;
  background-color: #f6ffed;
}

.select1 {
  display: flex;
  justify-content: space-between;
}

//去掉表格底部的线条
::v-deep(.p-paginator-bottom) {
  border-bottom: none !important;
}
::v-deep(.p-checkbox .p-checkbox-box.p-highlight) {
  border-color: #12a25d;
  background: #12a25d;
}
::v-deep(.p-checkbox .p-checkbox-box.p-highlight:hover) {
  border-color: #12a25d;
  background: #12a25d;
}
::v-deep(.p-calendar-w-btn .p-button) {
  border-color: #12a25d;
  background: #12a25d;
}
::v-deep(.p-checkbox .p-checkbox-box:hover) {
  border-color: #12a25d;
}
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight) {
  background: #12a25d;
  border-color: #12a25d;
  color: #ffffff;
}
::v-deep(.p-checkbox .p-checkbox-box.p-highlight) {
  border-color: #12a25d;
  background: #12a25d;
}
::v-deep(.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover) {
  border-color: #12a25d;
}
</style>
